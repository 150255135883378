<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form inline label-position="right" label-width="60px" size="small" class="query-form">
        <el-form-item label="搜索">
          <el-input v-model="key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item label="模块">
          <el-select
            v-model="query.parentID"
            placeholder="请选择上一级"
            filterable
            clearable
            @change="selList();"
          >
            <el-option
              v-for="item in parentList"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selList();">查询</el-button>
          <el-button type="success" @click="dialogVisible=true;form={isVisible:true};">新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>菜单列表</span>
        </div>
        <!-- 列表定义 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width:100%;"
          :tree-props="{children:'children'}"
        >
          <el-table-column prop="name" label="菜单名称"></el-table-column>
          <el-table-column prop="sortValue" label="排序"></el-table-column>
          <el-table-column prop="icon" label="图标"></el-table-column>
          <el-table-column prop="linkUrl" label="路径"></el-table-column>
          <el-table-column label="上一级菜单">
            <template
              slot-scope="scope"
              v-if="scope.row.parentInfo!=null"
            >{{scope.row.parentInfo.name}}</template>
          </el-table-column>
          <el-table-column prop="isVisible" label="是否显示">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isVisible==true">是</el-tag>
              <el-tag type="warn" v-else>否</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip content="编辑" placement="top">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="editClick(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top">
                <el-button
                  type="warning"
                  size="mini"
                  icon="el-icon-delete"
                  @click="delClick(scope.row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top:20px;">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50,100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- 操作对话框 -->
    <el-dialog :title="form.id>0?'编辑菜单信息':'添加菜单信息'" :visible.sync="dialogVisible">
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-form-item
          label="菜单名称"
          prop="name"
          :rules="[
        {required:true,message:'请输入菜单名称'}
        ]"
        >
          <el-input v-model="form.name" placeholder="请输入菜单名称"></el-input>
        </el-form-item>

        <el-form-item
          label="排序"
          prop="sortValue"
          :rules="[{required:true,message:'不能为空'},{type:'number',message:'请填写数字'}]"
        >
          <el-input v-model.number="form.sortValue" placeholder="请输入排序菜单"></el-input>
        </el-form-item>

        <el-form-item label="图标" prop="icon" :rules="[{required:true,message:'不能为空'}]">
          <el-input v-model="form.icon" placeholder="请输入图标"></el-input>
        </el-form-item>

        <el-form-item label="路径" prop="linkUrl">
          <el-input v-model="form.linkUrl" placeholder="请输入路径"></el-input>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="是否显示">
              <el-switch v-model="form.isVisible"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="上一级菜单" prop="parentID">
              <el-select v-model="form.parentID" placeholder="请选择上一级菜单" filterable>
                <el-option
                  v-for="item in parentList "
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit">提交</el-button>
          <el-button @click="dialogVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
export default {
  data() {
    return {
      key: "",
      query: {}, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      id: 0,
      parentList: [], //父类菜单
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    //查询列表
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      params.key = _this.key;
      var link = url.getSys("getlist_nav");
      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //搜索查询
    selList: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    //获取父类菜单列表
    parent: function () {
      //console.info(parentList);
      var _this = this;
      var link = url.getSys("getnavbyparent");
      $.get(link, {}, (data) => {
        _this.parentList = data;
      });
    },
    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getSys("addorupdate_nav");
      var info = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台添加
          //添加或者修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //编辑
    editClick: function (row) {
      this.form = Object.assign({}, row);
      this.dialogVisible = true;
    },
    //删除
    delClick: function (row) {
      var _this = this;
      var link = url.getSys("delete_nav"); //获取请求地址
      var id = row.id; //获取对象id

      this.$confirm("确定要删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { idList: id }, (res) => {
            _this.btnLoading = false;
            if (res.status) {
              _this.msg = res;
              _this.getList();
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted() {
    //获取服务器列表
    this.getList();
    //获取父类菜单
    this.parent();
  },
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
